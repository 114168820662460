import * as _jquery2 from "jquery";
var _jquery = "default" in _jquery2 ? _jquery2.default : _jquery2;
var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;
/*!
 * Datepicker for Bootstrap v1.10.0 (https://github.com/uxsolutions/bootstrap-datepicker)
 *
 * Licensed under the Apache License v2.0 (https://www.apache.org/licenses/LICENSE-2.0)
 */

(function (factory) {
  if (true) {
    factory(_jquery);
  }
})(function ($, undefined) {
  function UTCDate() {
    return new Date(Date.UTC.apply(Date, arguments));
  }
  function UTCToday() {
    var today = new Date();
    return UTCDate(today.getFullYear(), today.getMonth(), today.getDate());
  }
  function isUTCEquals(date1, date2) {
    return date1.getUTCFullYear() === date2.getUTCFullYear() && date1.getUTCMonth() === date2.getUTCMonth() && date1.getUTCDate() === date2.getUTCDate();
  }
  function alias(method, deprecationMsg) {
    return function () {
      if (deprecationMsg !== undefined) {
        $.fn.datepicker.deprecated(deprecationMsg);
      }
      return (this || _global)[method].apply(this || _global, arguments);
    };
  }
  function isValidDate(d) {
    return d && !isNaN(d.getTime());
  }
  var DateArray = function () {
    var extras = {
      get: function (i) {
        return this.slice(i)[0];
      },
      contains: function (d) {
        // Array.indexOf is not cross-browser;
        // $.inArray doesn't work with Dates
        var val = d && d.valueOf();
        for (var i = 0, l = (this || _global).length; i < l; i++)
        // Use date arithmetic to allow dates with different times to match
        if (0 <= (this || _global)[i].valueOf() - val && (this || _global)[i].valueOf() - val < 1000 * 60 * 60 * 24) return i;
        return -1;
      },
      remove: function (i) {
        this.splice(i, 1);
      },
      replace: function (new_array) {
        if (!new_array) return;
        if (!Array.isArray(new_array)) new_array = [new_array];
        this.clear();
        (this || _global).push.apply(this || _global, new_array);
      },
      clear: function () {
        (this || _global).length = 0;
      },
      copy: function () {
        var a = new DateArray();
        a.replace(this || _global);
        return a;
      }
    };
    return function () {
      var a = [];
      a.push.apply(a, arguments);
      $.extend(a, extras);
      return a;
    };
  }();

  // Picker object

  var Datepicker = function (element, options) {
    $.data(element, "datepicker", this || _global);
    (this || _global)._events = [];
    (this || _global)._secondaryEvents = [];
    this._process_options(options);
    (this || _global).dates = new DateArray();
    (this || _global).viewDate = (this || _global).o.defaultViewDate;
    (this || _global).focusDate = null;
    (this || _global).element = $(element);
    (this || _global).isInput = (this || _global).element.is("input");
    (this || _global).inputField = (this || _global).isInput ? (this || _global).element : (this || _global).element.find("input");
    (this || _global).component = (this || _global).element.hasClass("date") ? (this || _global).element.find(".add-on, .input-group-addon, .input-group-append, .input-group-prepend, .btn") : false;
    if ((this || _global).component && (this || _global).component.length === 0) {
      (this || _global).component = false;
    }
    if ((this || _global).o.isInline === null) {
      (this || _global).isInline = !(this || _global).component && !(this || _global).isInput;
    } else {
      (this || _global).isInline = (this || _global).o.isInline;
    }
    (this || _global).picker = $(DPGlobal.template);

    // Checking templates and inserting
    if (this._check_template((this || _global).o.templates.leftArrow)) {
      (this || _global).picker.find(".prev").html((this || _global).o.templates.leftArrow);
    }
    if (this._check_template((this || _global).o.templates.rightArrow)) {
      (this || _global).picker.find(".next").html((this || _global).o.templates.rightArrow);
    }
    this._buildEvents();
    this._attachEvents();
    if ((this || _global).isInline) {
      (this || _global).picker.addClass("datepicker-inline").appendTo((this || _global).element);
    } else {
      (this || _global).picker.addClass("datepicker-dropdown dropdown-menu");
    }
    if ((this || _global).o.rtl) {
      (this || _global).picker.addClass("datepicker-rtl");
    }
    if ((this || _global).o.calendarWeeks) {
      (this || _global).picker.find(".datepicker-days .datepicker-switch, thead .datepicker-title, tfoot .today, tfoot .clear").attr("colspan", function (i, val) {
        return Number(val) + 1;
      });
    }
    this._process_options({
      startDate: (this || _global)._o.startDate,
      endDate: (this || _global)._o.endDate,
      daysOfWeekDisabled: (this || _global).o.daysOfWeekDisabled,
      daysOfWeekHighlighted: (this || _global).o.daysOfWeekHighlighted,
      datesDisabled: (this || _global).o.datesDisabled
    });
    (this || _global)._allow_update = false;
    this.setViewMode((this || _global).o.startView);
    (this || _global)._allow_update = true;
    this.fillDow();
    this.fillMonths();
    this.update();
    if ((this || _global).isInline) {
      this.show();
    }
  };
  Datepicker.prototype = {
    constructor: Datepicker,
    _resolveViewName: function (view) {
      $.each(DPGlobal.viewModes, function (i, viewMode) {
        if (view === i || $.inArray(view, viewMode.names) !== -1) {
          view = i;
          return false;
        }
      });
      return view;
    },
    _resolveDaysOfWeek: function (daysOfWeek) {
      if (!Array.isArray(daysOfWeek)) daysOfWeek = daysOfWeek.split(/[,\s]*/);
      return $.map(daysOfWeek, Number);
    },
    _check_template: function (tmp) {
      try {
        // If empty
        if (tmp === undefined || tmp === "") {
          return false;
        }
        // If no html, everything ok
        if ((tmp.match(/[<>]/g) || []).length <= 0) {
          return true;
        }
        // Checking if html is fine
        var jDom = $(tmp);
        return jDom.length > 0;
      } catch (ex) {
        return false;
      }
    },
    _process_options: function (opts) {
      // Store raw options for reference
      (this || _global)._o = $.extend({}, (this || _global)._o, opts);
      // Processed options
      var o = (this || _global).o = $.extend({}, (this || _global)._o);

      // Check if "de-DE" style date is available, if not language should
      // fallback to 2 letter code eg "de"
      var lang = o.language;
      if (!dates[lang]) {
        lang = lang.split("-")[0];
        if (!dates[lang]) lang = defaults.language;
      }
      o.language = lang;

      // Retrieve view index from any aliases
      o.startView = this._resolveViewName(o.startView);
      o.minViewMode = this._resolveViewName(o.minViewMode);
      o.maxViewMode = this._resolveViewName(o.maxViewMode);

      // Check view is between min and max
      o.startView = Math.max((this || _global).o.minViewMode, Math.min((this || _global).o.maxViewMode, o.startView));

      // true, false, or Number > 0
      if (o.multidate !== true) {
        o.multidate = Number(o.multidate) || false;
        if (o.multidate !== false) o.multidate = Math.max(0, o.multidate);
      }
      o.multidateSeparator = String(o.multidateSeparator);
      o.weekStart %= 7;
      o.weekEnd = (o.weekStart + 6) % 7;
      var format = DPGlobal.parseFormat(o.format);
      if (o.startDate !== -Infinity) {
        if (!!o.startDate) {
          if (o.startDate instanceof Date) o.startDate = this._local_to_utc(this._zero_time(o.startDate));else o.startDate = DPGlobal.parseDate(o.startDate, format, o.language, o.assumeNearbyYear);
        } else {
          o.startDate = -Infinity;
        }
      }
      if (o.endDate !== Infinity) {
        if (!!o.endDate) {
          if (o.endDate instanceof Date) o.endDate = this._local_to_utc(this._zero_time(o.endDate));else o.endDate = DPGlobal.parseDate(o.endDate, format, o.language, o.assumeNearbyYear);
        } else {
          o.endDate = Infinity;
        }
      }
      o.daysOfWeekDisabled = this._resolveDaysOfWeek(o.daysOfWeekDisabled || []);
      o.daysOfWeekHighlighted = this._resolveDaysOfWeek(o.daysOfWeekHighlighted || []);
      o.datesDisabled = o.datesDisabled || [];
      if (!Array.isArray(o.datesDisabled)) {
        o.datesDisabled = o.datesDisabled.split(",");
      }
      o.datesDisabled = $.map(o.datesDisabled, function (d) {
        return DPGlobal.parseDate(d, format, o.language, o.assumeNearbyYear);
      });
      var plc = String(o.orientation).toLowerCase().split(/\s+/g),
        _plc = o.orientation.toLowerCase();
      plc = $.grep(plc, function (word) {
        return /^auto|left|right|top|bottom$/.test(word);
      });
      o.orientation = {
        x: "auto",
        y: "auto"
      };
      if (!_plc || _plc === "auto") ; // no action
      else if (plc.length === 1) {
        switch (plc[0]) {
          case "top":
          case "bottom":
            o.orientation.y = plc[0];
            break;
          case "left":
          case "right":
            o.orientation.x = plc[0];
            break;
        }
      } else {
        _plc = $.grep(plc, function (word) {
          return /^left|right$/.test(word);
        });
        o.orientation.x = _plc[0] || "auto";
        _plc = $.grep(plc, function (word) {
          return /^top|bottom$/.test(word);
        });
        o.orientation.y = _plc[0] || "auto";
      }
      if (o.defaultViewDate instanceof Date || typeof o.defaultViewDate === "string") {
        o.defaultViewDate = DPGlobal.parseDate(o.defaultViewDate, format, o.language, o.assumeNearbyYear);
      } else if (o.defaultViewDate) {
        var year = o.defaultViewDate.year || new Date().getFullYear();
        var month = o.defaultViewDate.month || 0;
        var day = o.defaultViewDate.day || 1;
        o.defaultViewDate = UTCDate(year, month, day);
      } else {
        o.defaultViewDate = UTCToday();
      }
    },
    _applyEvents: function (evs) {
      for (var i = 0, el, ch, ev; i < evs.length; i++) {
        el = evs[i][0];
        if (evs[i].length === 2) {
          ch = undefined;
          ev = evs[i][1];
        } else if (evs[i].length === 3) {
          ch = evs[i][1];
          ev = evs[i][2];
        }
        el.on(ev, ch);
      }
    },
    _unapplyEvents: function (evs) {
      for (var i = 0, el, ev, ch; i < evs.length; i++) {
        el = evs[i][0];
        if (evs[i].length === 2) {
          ch = undefined;
          ev = evs[i][1];
        } else if (evs[i].length === 3) {
          ch = evs[i][1];
          ev = evs[i][2];
        }
        el.off(ev, ch);
      }
    },
    _buildEvents: function () {
      var events = {
        keyup: $.proxy(function (e) {
          if ($.inArray(e.keyCode, [27, 37, 39, 38, 40, 32, 13, 9]) === -1) this.update();
        }, this || _global),
        keydown: $.proxy((this || _global).keydown, this || _global),
        paste: $.proxy((this || _global).paste, this || _global)
      };
      if ((this || _global).o.showOnFocus === true) {
        events.focus = $.proxy((this || _global).show, this || _global);
      }
      if ((this || _global).isInput) {
        // single input
        (this || _global)._events = [[(this || _global).element, events]];
      }
      // component: input + button
      else if ((this || _global).component && (this || _global).inputField.length) {
        (this || _global)._events = [
        // For components that are not readonly, allow keyboard nav
        [(this || _global).inputField, events], [(this || _global).component, {
          click: $.proxy((this || _global).show, this || _global)
        }]];
      } else {
        (this || _global)._events = [[(this || _global).element, {
          click: $.proxy((this || _global).show, this || _global),
          keydown: $.proxy((this || _global).keydown, this || _global)
        }]];
      }
      (this || _global)._events.push(
      // Component: listen for blur on element descendants
      [(this || _global).element, "*", {
        blur: $.proxy(function (e) {
          (this || _global)._focused_from = e.target;
        }, this || _global)
      }],
      // Input: listen for blur on element
      [(this || _global).element, {
        blur: $.proxy(function (e) {
          (this || _global)._focused_from = e.target;
        }, this || _global)
      }]);
      if ((this || _global).o.immediateUpdates) {
        // Trigger input updates immediately on changed year/month
        (this || _global)._events.push([(this || _global).element, {
          "changeYear changeMonth": $.proxy(function (e) {
            this.update(e.date);
          }, this || _global)
        }]);
      }
      (this || _global)._secondaryEvents = [[(this || _global).picker, {
        click: $.proxy((this || _global).click, this || _global)
      }], [(this || _global).picker, ".prev, .next", {
        click: $.proxy((this || _global).navArrowsClick, this || _global)
      }], [(this || _global).picker, ".day:not(.disabled)", {
        click: $.proxy((this || _global).dayCellClick, this || _global)
      }], [$(window), {
        resize: $.proxy((this || _global).place, this || _global)
      }], [$(document), {
        "mousedown touchstart": $.proxy(function (e) {
          // Clicked outside the datepicker, hide it
          if (!((this || _global).element.is(e.target) || (this || _global).element.find(e.target).length || (this || _global).picker.is(e.target) || (this || _global).picker.find(e.target).length || (this || _global).isInline)) {
            this.hide();
          }
        }, this || _global)
      }]];
    },
    _attachEvents: function () {
      this._detachEvents();
      this._applyEvents((this || _global)._events);
    },
    _detachEvents: function () {
      this._unapplyEvents((this || _global)._events);
    },
    _attachSecondaryEvents: function () {
      this._detachSecondaryEvents();
      this._applyEvents((this || _global)._secondaryEvents);
    },
    _detachSecondaryEvents: function () {
      this._unapplyEvents((this || _global)._secondaryEvents);
    },
    _trigger: function (event, altdate) {
      var date = altdate || (this || _global).dates.get(-1),
        local_date = this._utc_to_local(date);
      (this || _global).element.trigger({
        type: event,
        date: local_date,
        viewMode: (this || _global).viewMode,
        dates: $.map((this || _global).dates, (this || _global)._utc_to_local),
        format: $.proxy(function (ix, format) {
          if (arguments.length === 0) {
            ix = (this || _global).dates.length - 1;
            format = (this || _global).o.format;
          } else if (typeof ix === "string") {
            format = ix;
            ix = (this || _global).dates.length - 1;
          }
          format = format || (this || _global).o.format;
          var date = (this || _global).dates.get(ix);
          return DPGlobal.formatDate(date, format, (this || _global).o.language);
        }, this || _global)
      });
    },
    show: function () {
      if ((this || _global).inputField.is(":disabled") || (this || _global).inputField.prop("readonly") && (this || _global).o.enableOnReadonly === false) return;
      if (!(this || _global).isInline) (this || _global).picker.appendTo((this || _global).o.container);
      this.place();
      (this || _global).picker.show();
      this._attachSecondaryEvents();
      this._trigger("show");
      if ((window.navigator.msMaxTouchPoints || "ontouchstart" in document) && (this || _global).o.disableTouchKeyboard) {
        $((this || _global).element).blur();
      }
      return this || _global;
    },
    hide: function () {
      if ((this || _global).isInline || !(this || _global).picker.is(":visible")) return this || _global;
      (this || _global).focusDate = null;
      (this || _global).picker.hide().detach();
      this._detachSecondaryEvents();
      this.setViewMode((this || _global).o.startView);
      if ((this || _global).o.forceParse && (this || _global).inputField.val()) this.setValue();
      this._trigger("hide");
      return this || _global;
    },
    destroy: function () {
      this.hide();
      this._detachEvents();
      this._detachSecondaryEvents();
      (this || _global).picker.remove();
      delete (this || _global).element.data().datepicker;
      if (!(this || _global).isInput) {
        delete (this || _global).element.data().date;
      }
      return this || _global;
    },
    paste: function (e) {
      var dateString;
      if (e.originalEvent.clipboardData && e.originalEvent.clipboardData.types && $.inArray("text/plain", e.originalEvent.clipboardData.types) !== -1) {
        dateString = e.originalEvent.clipboardData.getData("text/plain");
      } else if (window.clipboardData) {
        dateString = window.clipboardData.getData("Text");
      } else {
        return;
      }
      this.setDate(dateString);
      this.update();
      e.preventDefault();
    },
    _utc_to_local: function (utc) {
      if (!utc) {
        return utc;
      }
      var local = new Date(utc.getTime() + utc.getTimezoneOffset() * 60000);
      if (local.getTimezoneOffset() !== utc.getTimezoneOffset()) {
        local = new Date(utc.getTime() + local.getTimezoneOffset() * 60000);
      }
      return local;
    },
    _local_to_utc: function (local) {
      return local && new Date(local.getTime() - local.getTimezoneOffset() * 60000);
    },
    _zero_time: function (local) {
      return local && new Date(local.getFullYear(), local.getMonth(), local.getDate());
    },
    _zero_utc_time: function (utc) {
      return utc && UTCDate(utc.getUTCFullYear(), utc.getUTCMonth(), utc.getUTCDate());
    },
    getDates: function () {
      return $.map((this || _global).dates, (this || _global)._utc_to_local);
    },
    getUTCDates: function () {
      return $.map((this || _global).dates, function (d) {
        return new Date(d);
      });
    },
    getDate: function () {
      return this._utc_to_local(this.getUTCDate());
    },
    getUTCDate: function () {
      var selected_date = (this || _global).dates.get(-1);
      if (selected_date !== undefined) {
        return new Date(selected_date);
      } else {
        return null;
      }
    },
    clearDates: function () {
      (this || _global).inputField.val("");
      this._trigger("changeDate");
      this.update();
      if ((this || _global).o.autoclose) {
        this.hide();
      }
    },
    setDates: function () {
      var args = Array.isArray(arguments[0]) ? arguments[0] : arguments;
      (this || _global).update.apply(this || _global, args);
      this._trigger("changeDate");
      this.setValue();
      return this || _global;
    },
    setUTCDates: function () {
      var args = Array.isArray(arguments[0]) ? arguments[0] : arguments;
      (this || _global).setDates.apply(this || _global, $.map(args, (this || _global)._utc_to_local));
      return this || _global;
    },
    setDate: alias("setDates"),
    setUTCDate: alias("setUTCDates"),
    remove: alias("destroy", "Method `remove` is deprecated and will be removed in version 2.0. Use `destroy` instead"),
    setValue: function () {
      var formatted = this.getFormattedDate();
      (this || _global).inputField.val(formatted);
      return this || _global;
    },
    getFormattedDate: function (format) {
      if (format === undefined) format = (this || _global).o.format;
      var lang = (this || _global).o.language;
      return $.map((this || _global).dates, function (d) {
        return DPGlobal.formatDate(d, format, lang);
      }).join((this || _global).o.multidateSeparator);
    },
    getStartDate: function () {
      return (this || _global).o.startDate;
    },
    setStartDate: function (startDate) {
      this._process_options({
        startDate: startDate
      });
      this.update();
      this.updateNavArrows();
      return this || _global;
    },
    getEndDate: function () {
      return (this || _global).o.endDate;
    },
    setEndDate: function (endDate) {
      this._process_options({
        endDate: endDate
      });
      this.update();
      this.updateNavArrows();
      return this || _global;
    },
    setDaysOfWeekDisabled: function (daysOfWeekDisabled) {
      this._process_options({
        daysOfWeekDisabled: daysOfWeekDisabled
      });
      this.update();
      return this || _global;
    },
    setDaysOfWeekHighlighted: function (daysOfWeekHighlighted) {
      this._process_options({
        daysOfWeekHighlighted: daysOfWeekHighlighted
      });
      this.update();
      return this || _global;
    },
    setDatesDisabled: function (datesDisabled) {
      this._process_options({
        datesDisabled: datesDisabled
      });
      this.update();
      return this || _global;
    },
    place: function () {
      if ((this || _global).isInline) return this || _global;
      var calendarWidth = (this || _global).picker.outerWidth(),
        calendarHeight = (this || _global).picker.outerHeight(),
        visualPadding = 10,
        container = $((this || _global).o.container),
        windowWidth = container.width(),
        scrollTop = (this || _global).o.container === "body" ? $(document).scrollTop() : container.scrollTop(),
        appendOffset = container.offset();
      var parentsZindex = [0];
      (this || _global).element.parents().each(function () {
        var itemZIndex = $(this || _global).css("z-index");
        if (itemZIndex !== "auto" && Number(itemZIndex) !== 0) parentsZindex.push(Number(itemZIndex));
      });
      var zIndex = Math.max.apply(Math, parentsZindex) + (this || _global).o.zIndexOffset;
      var offset = (this || _global).component ? (this || _global).component.parent().offset() : (this || _global).element.offset();
      var height = (this || _global).component ? (this || _global).component.outerHeight(true) : (this || _global).element.outerHeight(false);
      var width = (this || _global).component ? (this || _global).component.outerWidth(true) : (this || _global).element.outerWidth(false);
      var left = offset.left - appendOffset.left;
      var top = offset.top - appendOffset.top;
      if ((this || _global).o.container !== "body") {
        top += scrollTop;
      }
      (this || _global).picker.removeClass("datepicker-orient-top datepicker-orient-bottom " + "datepicker-orient-right datepicker-orient-left");
      if ((this || _global).o.orientation.x !== "auto") {
        (this || _global).picker.addClass("datepicker-orient-" + (this || _global).o.orientation.x);
        if ((this || _global).o.orientation.x === "right") left -= calendarWidth - width;
      }
      // auto x orientation is best-placement: if it crosses a window
      // edge, fudge it sideways
      else {
        if (offset.left < 0) {
          // component is outside the window on the left side. Move it into visible range
          (this || _global).picker.addClass("datepicker-orient-left");
          left -= offset.left - visualPadding;
        } else if (left + calendarWidth > windowWidth) {
          // the calendar passes the widow right edge. Align it to component right side
          (this || _global).picker.addClass("datepicker-orient-right");
          left += width - calendarWidth;
        } else {
          if ((this || _global).o.rtl) {
            // Default to right
            (this || _global).picker.addClass("datepicker-orient-right");
          } else {
            // Default to left
            (this || _global).picker.addClass("datepicker-orient-left");
          }
        }
      }

      // auto y orientation is best-situation: top or bottom, no fudging,
      // decision based on which shows more of the calendar
      var yorient = (this || _global).o.orientation.y,
        top_overflow;
      if (yorient === "auto") {
        top_overflow = -scrollTop + top - calendarHeight;
        yorient = top_overflow < 0 ? "bottom" : "top";
      }
      (this || _global).picker.addClass("datepicker-orient-" + yorient);
      if (yorient === "top") top -= calendarHeight + parseInt((this || _global).picker.css("padding-top"));else top += height;
      if ((this || _global).o.rtl) {
        var right = windowWidth - (left + width);
        (this || _global).picker.css({
          top: top,
          right: right,
          zIndex: zIndex
        });
      } else {
        (this || _global).picker.css({
          top: top,
          left: left,
          zIndex: zIndex
        });
      }
      return this || _global;
    },
    _allow_update: true,
    update: function () {
      if (!(this || _global)._allow_update) return this || _global;
      var oldDates = (this || _global).dates.copy(),
        dates = [],
        fromArgs = false;
      if (arguments.length) {
        $.each(arguments, $.proxy(function (i, date) {
          if (date instanceof Date) date = this._local_to_utc(date);
          dates.push(date);
        }, this || _global));
        fromArgs = true;
      } else {
        dates = (this || _global).isInput ? (this || _global).element.val() : (this || _global).element.data("date") || (this || _global).inputField.val();
        if (dates && (this || _global).o.multidate) dates = dates.split((this || _global).o.multidateSeparator);else dates = [dates];
        delete (this || _global).element.data().date;
      }
      dates = $.map(dates, $.proxy(function (date) {
        return DPGlobal.parseDate(date, (this || _global).o.format, (this || _global).o.language, (this || _global).o.assumeNearbyYear);
      }, this || _global));
      dates = $.grep(dates, $.proxy(function (date) {
        return !this.dateWithinRange(date) || !date;
      }, this || _global), true);
      (this || _global).dates.replace(dates);
      if ((this || _global).o.updateViewDate) {
        if ((this || _global).dates.length) (this || _global).viewDate = new Date((this || _global).dates.get(-1));else if ((this || _global).viewDate < (this || _global).o.startDate) (this || _global).viewDate = new Date((this || _global).o.startDate);else if ((this || _global).viewDate > (this || _global).o.endDate) (this || _global).viewDate = new Date((this || _global).o.endDate);else (this || _global).viewDate = (this || _global).o.defaultViewDate;
      }
      if (fromArgs) {
        // setting date by clicking
        this.setValue();
        (this || _global).element.change();
      } else if ((this || _global).dates.length) {
        // setting date by typing
        if (String(oldDates) !== String((this || _global).dates) && fromArgs) {
          this._trigger("changeDate");
          (this || _global).element.change();
        }
      }
      if (!(this || _global).dates.length && oldDates.length) {
        this._trigger("clearDate");
        (this || _global).element.change();
      }
      this.fill();
      return this || _global;
    },
    fillDow: function () {
      if ((this || _global).o.showWeekDays) {
        var dowCnt = (this || _global).o.weekStart,
          html = "<tr>";
        if ((this || _global).o.calendarWeeks) {
          html += "<th class=\"cw\">&#160;</th>";
        }
        while (dowCnt < (this || _global).o.weekStart + 7) {
          html += "<th class=\"dow";
          if ($.inArray(dowCnt, (this || _global).o.daysOfWeekDisabled) !== -1) html += " disabled";
          html += "\">" + dates[(this || _global).o.language].daysMin[dowCnt++ % 7] + "</th>";
        }
        html += "</tr>";
        (this || _global).picker.find(".datepicker-days thead").append(html);
      }
    },
    fillMonths: function () {
      var localDate = this._utc_to_local((this || _global).viewDate);
      var html = "";
      var focused;
      for (var i = 0; i < 12; i++) {
        focused = localDate && localDate.getMonth() === i ? " focused" : "";
        html += "<span class=\"month" + focused + "\">" + dates[(this || _global).o.language].monthsShort[i] + "</span>";
      }
      (this || _global).picker.find(".datepicker-months td").html(html);
    },
    setRange: function (range) {
      if (!range || !range.length) delete (this || _global).range;else (this || _global).range = $.map(range, function (d) {
        return d.valueOf();
      });
      this.fill();
    },
    getClassNames: function (date) {
      var cls = [],
        year = (this || _global).viewDate.getUTCFullYear(),
        month = (this || _global).viewDate.getUTCMonth(),
        today = UTCToday();
      if (date.getUTCFullYear() < year || date.getUTCFullYear() === year && date.getUTCMonth() < month) {
        cls.push("old");
      } else if (date.getUTCFullYear() > year || date.getUTCFullYear() === year && date.getUTCMonth() > month) {
        cls.push("new");
      }
      if ((this || _global).focusDate && date.valueOf() === (this || _global).focusDate.valueOf()) cls.push("focused");
      // Compare internal UTC date with UTC today, not local today
      if ((this || _global).o.todayHighlight && isUTCEquals(date, today)) {
        cls.push("today");
      }
      if ((this || _global).dates.contains(date) !== -1) cls.push("active");
      if (!this.dateWithinRange(date)) {
        cls.push("disabled");
      }
      if (this.dateIsDisabled(date)) {
        cls.push("disabled", "disabled-date");
      }
      if ($.inArray(date.getUTCDay(), (this || _global).o.daysOfWeekHighlighted) !== -1) {
        cls.push("highlighted");
      }
      if ((this || _global).range) {
        if (date > (this || _global).range[0] && date < (this || _global).range[(this || _global).range.length - 1]) {
          cls.push("range");
        }
        if ($.inArray(date.valueOf(), (this || _global).range) !== -1) {
          cls.push("selected");
        }
        if (date.valueOf() === (this || _global).range[0]) {
          cls.push("range-start");
        }
        if (date.valueOf() === (this || _global).range[(this || _global).range.length - 1]) {
          cls.push("range-end");
        }
      }
      return cls;
    },
    _fill_yearsView: function (selector, cssClass, factor, year, startYear, endYear, beforeFn) {
      var html = "";
      var step = factor / 10;
      var view = (this || _global).picker.find(selector);
      var startVal = Math.floor(year / factor) * factor;
      var endVal = startVal + step * 9;
      var focusedVal = Math.floor((this || _global).viewDate.getFullYear() / step) * step;
      var selected = $.map((this || _global).dates, function (d) {
        return Math.floor(d.getUTCFullYear() / step) * step;
      });
      var classes, tooltip, before;
      for (var currVal = startVal - step; currVal <= endVal + step; currVal += step) {
        classes = [cssClass];
        tooltip = null;
        if (currVal === startVal - step) {
          classes.push("old");
        } else if (currVal === endVal + step) {
          classes.push("new");
        }
        if ($.inArray(currVal, selected) !== -1) {
          classes.push("active");
        }
        if (currVal < startYear || currVal > endYear) {
          classes.push("disabled");
        }
        if (currVal === focusedVal) {
          classes.push("focused");
        }
        if (beforeFn !== $.noop) {
          before = beforeFn(new Date(currVal, 0, 1));
          if (before === undefined) {
            before = {};
          } else if (typeof before === "boolean") {
            before = {
              enabled: before
            };
          } else if (typeof before === "string") {
            before = {
              classes: before
            };
          }
          if (before.enabled === false) {
            classes.push("disabled");
          }
          if (before.classes) {
            classes = classes.concat(before.classes.split(/\s+/));
          }
          if (before.tooltip) {
            tooltip = before.tooltip;
          }
        }
        html += "<span class=\"" + classes.join(" ") + "\"" + (tooltip ? " title=\"" + tooltip + "\"" : "") + ">" + currVal + "</span>";
      }
      view.find(".datepicker-switch").text(startVal + "-" + endVal);
      view.find("td").html(html);
    },
    fill: function () {
      var d = new Date((this || _global).viewDate),
        year = d.getUTCFullYear(),
        month = d.getUTCMonth(),
        startYear = (this || _global).o.startDate !== -Infinity ? (this || _global).o.startDate.getUTCFullYear() : -Infinity,
        startMonth = (this || _global).o.startDate !== -Infinity ? (this || _global).o.startDate.getUTCMonth() : -Infinity,
        endYear = (this || _global).o.endDate !== Infinity ? (this || _global).o.endDate.getUTCFullYear() : Infinity,
        endMonth = (this || _global).o.endDate !== Infinity ? (this || _global).o.endDate.getUTCMonth() : Infinity,
        todaytxt = dates[(this || _global).o.language].today || dates["en"].today || "",
        cleartxt = dates[(this || _global).o.language].clear || dates["en"].clear || "",
        titleFormat = dates[(this || _global).o.language].titleFormat || dates["en"].titleFormat,
        todayDate = UTCToday(),
        titleBtnVisible = ((this || _global).o.todayBtn === true || (this || _global).o.todayBtn === "linked") && todayDate >= (this || _global).o.startDate && todayDate <= (this || _global).o.endDate && !this.weekOfDateIsDisabled(todayDate),
        tooltip,
        before;
      if (isNaN(year) || isNaN(month)) return;
      (this || _global).picker.find(".datepicker-days .datepicker-switch").text(DPGlobal.formatDate(d, titleFormat, (this || _global).o.language));
      (this || _global).picker.find("tfoot .today").text(todaytxt).css("display", titleBtnVisible ? "table-cell" : "none");
      (this || _global).picker.find("tfoot .clear").text(cleartxt).css("display", (this || _global).o.clearBtn === true ? "table-cell" : "none");
      (this || _global).picker.find("thead .datepicker-title").text((this || _global).o.title).css("display", typeof (this || _global).o.title === "string" && (this || _global).o.title !== "" ? "table-cell" : "none");
      this.updateNavArrows();
      this.fillMonths();
      var prevMonth = UTCDate(year, month, 0),
        day = prevMonth.getUTCDate();
      prevMonth.setUTCDate(day - (prevMonth.getUTCDay() - (this || _global).o.weekStart + 7) % 7);
      var nextMonth = new Date(prevMonth);
      if (prevMonth.getUTCFullYear() < 100) {
        nextMonth.setUTCFullYear(prevMonth.getUTCFullYear());
      }
      nextMonth.setUTCDate(nextMonth.getUTCDate() + 42);
      nextMonth = nextMonth.valueOf();
      var html = [];
      var weekDay, clsName;
      while (prevMonth.valueOf() < nextMonth) {
        weekDay = prevMonth.getUTCDay();
        if (weekDay === (this || _global).o.weekStart) {
          html.push("<tr>");
          if ((this || _global).o.calendarWeeks) {
            // ISO 8601: First week contains first thursday.
            // ISO also states week starts on Monday, but we can be more abstract here.
            var
              // Start of current week: based on weekstart/current date
              ws = new Date(+prevMonth + ((this || _global).o.weekStart - weekDay - 7) % 7 * 86400000),
              // Thursday of this week
              th = new Date(Number(ws) + (7 + 4 - ws.getUTCDay()) % 7 * 86400000),
              // First Thursday of year, year from thursday
              yth = new Date(Number(yth = UTCDate(th.getUTCFullYear(), 0, 1)) + (7 + 4 - yth.getUTCDay()) % 7 * 86400000),
              // Calendar week: ms between thursdays, div ms per day, div 7 days
              calWeek = (th - yth) / 86400000 / 7 + 1;
            html.push("<td class=\"cw\">" + calWeek + "</td>");
          }
        }
        clsName = this.getClassNames(prevMonth);
        clsName.push("day");
        var content = prevMonth.getUTCDate();
        if ((this || _global).o.beforeShowDay !== $.noop) {
          before = (this || _global).o.beforeShowDay(this._utc_to_local(prevMonth));
          if (before === undefined) before = {};else if (typeof before === "boolean") before = {
            enabled: before
          };else if (typeof before === "string") before = {
            classes: before
          };
          if (before.enabled === false) clsName.push("disabled");
          if (before.classes) clsName = clsName.concat(before.classes.split(/\s+/));
          if (before.tooltip) tooltip = before.tooltip;
          if (before.content) content = before.content;
        }

        //Check if uniqueSort exists (supported by jquery >=1.12 and >=2.2)
        //Fallback to unique function for older jquery versions
        if (typeof $.uniqueSort === "function") {
          clsName = $.uniqueSort(clsName);
        } else {
          clsName = $.unique(clsName);
        }
        html.push("<td class=\"" + clsName.join(" ") + "\"" + (tooltip ? " title=\"" + tooltip + "\"" : "") + " data-date=\"" + prevMonth.getTime().toString() + "\">" + content + "</td>");
        tooltip = null;
        if (weekDay === (this || _global).o.weekEnd) {
          html.push("</tr>");
        }
        prevMonth.setUTCDate(prevMonth.getUTCDate() + 1);
      }
      (this || _global).picker.find(".datepicker-days tbody").html(html.join(""));
      var monthsTitle = dates[(this || _global).o.language].monthsTitle || dates["en"].monthsTitle || "Months";
      var months = (this || _global).picker.find(".datepicker-months").find(".datepicker-switch").text((this || _global).o.maxViewMode < 2 ? monthsTitle : year).end().find("tbody span").removeClass("active");
      $.each((this || _global).dates, function (i, d) {
        if (d.getUTCFullYear() === year) months.eq(d.getUTCMonth()).addClass("active");
      });
      if (year < startYear || year > endYear) {
        months.addClass("disabled");
      }
      if (year === startYear) {
        months.slice(0, startMonth).addClass("disabled");
      }
      if (year === endYear) {
        months.slice(endMonth + 1).addClass("disabled");
      }
      if ((this || _global).o.beforeShowMonth !== $.noop) {
        var that = this || _global;
        $.each(months, function (i, month) {
          var moDate = new Date(year, i, 1);
          var before = that.o.beforeShowMonth(moDate);
          if (before === undefined) before = {};else if (typeof before === "boolean") before = {
            enabled: before
          };else if (typeof before === "string") before = {
            classes: before
          };
          if (before.enabled === false && !$(month).hasClass("disabled")) $(month).addClass("disabled");
          if (before.classes) $(month).addClass(before.classes);
          if (before.tooltip) $(month).prop("title", before.tooltip);
        });
      }

      // Generating decade/years picker
      this._fill_yearsView(".datepicker-years", "year", 10, year, startYear, endYear, (this || _global).o.beforeShowYear);

      // Generating century/decades picker
      this._fill_yearsView(".datepicker-decades", "decade", 100, year, startYear, endYear, (this || _global).o.beforeShowDecade);

      // Generating millennium/centuries picker
      this._fill_yearsView(".datepicker-centuries", "century", 1000, year, startYear, endYear, (this || _global).o.beforeShowCentury);
    },
    updateNavArrows: function () {
      if (!(this || _global)._allow_update) return;
      var d = new Date((this || _global).viewDate),
        year = d.getUTCFullYear(),
        month = d.getUTCMonth(),
        startYear = (this || _global).o.startDate !== -Infinity ? (this || _global).o.startDate.getUTCFullYear() : -Infinity,
        startMonth = (this || _global).o.startDate !== -Infinity ? (this || _global).o.startDate.getUTCMonth() : -Infinity,
        endYear = (this || _global).o.endDate !== Infinity ? (this || _global).o.endDate.getUTCFullYear() : Infinity,
        endMonth = (this || _global).o.endDate !== Infinity ? (this || _global).o.endDate.getUTCMonth() : Infinity,
        prevIsDisabled,
        nextIsDisabled,
        factor = 1;
      switch ((this || _global).viewMode) {
        case 4:
          factor *= 10;
        /* falls through */
        case 3:
          factor *= 10;
        /* falls through */
        case 2:
          factor *= 10;
        /* falls through */
        case 1:
          prevIsDisabled = Math.floor(year / factor) * factor <= startYear;
          nextIsDisabled = Math.floor(year / factor) * factor + factor > endYear;
          break;
        case 0:
          prevIsDisabled = year <= startYear && month <= startMonth;
          nextIsDisabled = year >= endYear && month >= endMonth;
          break;
      }
      (this || _global).picker.find(".prev").toggleClass("disabled", prevIsDisabled);
      (this || _global).picker.find(".next").toggleClass("disabled", nextIsDisabled);
    },
    click: function (e) {
      e.preventDefault();
      e.stopPropagation();
      var target, dir, day, year, month;
      target = $(e.target);

      // Clicked on the switch
      if (target.hasClass("datepicker-switch") && (this || _global).viewMode !== (this || _global).o.maxViewMode) {
        this.setViewMode((this || _global).viewMode + 1);
      }

      // Clicked on today button
      if (target.hasClass("today") && !target.hasClass("day")) {
        this.setViewMode(0);
        this._setDate(UTCToday(), (this || _global).o.todayBtn === "linked" ? null : "view");
      }

      // Clicked on clear button
      if (target.hasClass("clear")) {
        this.clearDates();
      }
      if (!target.hasClass("disabled")) {
        // Clicked on a month, year, decade, century
        if (target.hasClass("month") || target.hasClass("year") || target.hasClass("decade") || target.hasClass("century")) {
          (this || _global).viewDate.setUTCDate(1);
          day = 1;
          if ((this || _global).viewMode === 1) {
            month = target.parent().find("span").index(target);
            year = (this || _global).viewDate.getUTCFullYear();
            (this || _global).viewDate.setUTCMonth(month);
          } else {
            month = 0;
            year = Number(target.text());
            (this || _global).viewDate.setUTCFullYear(year);
          }
          this._trigger(DPGlobal.viewModes[(this || _global).viewMode - 1].e, (this || _global).viewDate);
          if ((this || _global).viewMode === (this || _global).o.minViewMode) {
            this._setDate(UTCDate(year, month, day));
          } else {
            this.setViewMode((this || _global).viewMode - 1);
            this.fill();
          }
        }
      }
      if ((this || _global).picker.is(":visible") && (this || _global)._focused_from) {
        (this || _global)._focused_from.focus();
      }
      delete (this || _global)._focused_from;
    },
    dayCellClick: function (e) {
      var $target = $(e.currentTarget);
      var timestamp = $target.data("date");
      var date = new Date(timestamp);
      if ((this || _global).o.updateViewDate) {
        if (date.getUTCFullYear() !== (this || _global).viewDate.getUTCFullYear()) {
          this._trigger("changeYear", (this || _global).viewDate);
        }
        if (date.getUTCMonth() !== (this || _global).viewDate.getUTCMonth()) {
          this._trigger("changeMonth", (this || _global).viewDate);
        }
      }
      this._setDate(date);
    },
    // Clicked on prev or next
    navArrowsClick: function (e) {
      var $target = $(e.currentTarget);
      var dir = $target.hasClass("prev") ? -1 : 1;
      if ((this || _global).viewMode !== 0) {
        dir *= DPGlobal.viewModes[(this || _global).viewMode].navStep * 12;
      }
      (this || _global).viewDate = this.moveMonth((this || _global).viewDate, dir);
      this._trigger(DPGlobal.viewModes[(this || _global).viewMode].e, (this || _global).viewDate);
      this.fill();
    },
    _toggle_multidate: function (date) {
      var ix = (this || _global).dates.contains(date);
      if (!date) {
        (this || _global).dates.clear();
      }
      if (ix !== -1) {
        if ((this || _global).o.multidate === true || (this || _global).o.multidate > 1 || (this || _global).o.toggleActive) {
          (this || _global).dates.remove(ix);
        }
      } else if ((this || _global).o.multidate === false) {
        (this || _global).dates.clear();
        (this || _global).dates.push(date);
      } else {
        (this || _global).dates.push(date);
      }
      if (typeof (this || _global).o.multidate === "number") while ((this || _global).dates.length > (this || _global).o.multidate) (this || _global).dates.remove(0);
    },
    _setDate: function (date, which) {
      if (!which || which === "date") this._toggle_multidate(date && new Date(date));
      if (!which && (this || _global).o.updateViewDate || which === "view") (this || _global).viewDate = date && new Date(date);
      this.fill();
      this.setValue();
      if (!which || which !== "view") {
        this._trigger("changeDate");
      }
      (this || _global).inputField.trigger("change");
      if ((this || _global).o.autoclose && (!which || which === "date")) {
        this.hide();
      }
    },
    moveDay: function (date, dir) {
      var newDate = new Date(date);
      newDate.setUTCDate(date.getUTCDate() + dir);
      return newDate;
    },
    moveWeek: function (date, dir) {
      return this.moveDay(date, dir * 7);
    },
    moveMonth: function (date, dir) {
      if (!isValidDate(date)) return (this || _global).o.defaultViewDate;
      if (!dir) return date;
      var new_date = new Date(date.valueOf()),
        day = new_date.getUTCDate(),
        month = new_date.getUTCMonth(),
        mag = Math.abs(dir),
        new_month,
        test;
      dir = dir > 0 ? 1 : -1;
      if (mag === 1) {
        test = dir === -1
        // If going back one month, make sure month is not current month
        // (eg, Mar 31 -> Feb 31 == Feb 28, not Mar 02)
        ? function () {
          return new_date.getUTCMonth() === month;
        }
        // If going forward one month, make sure month is as expected
        // (eg, Jan 31 -> Feb 31 == Feb 28, not Mar 02)
        : function () {
          return new_date.getUTCMonth() !== new_month;
        };
        new_month = month + dir;
        new_date.setUTCMonth(new_month);
        // Dec -> Jan (12) or Jan -> Dec (-1) -- limit expected date to 0-11
        new_month = (new_month + 12) % 12;
      } else {
        // For magnitudes >1, move one month at a time...
        for (var i = 0; i < mag; i++)
        // ...which might decrease the day (eg, Jan 31 to Feb 28, etc)...
        new_date = this.moveMonth(new_date, dir);
        // ...then reset the day, keeping it in the new month
        new_month = new_date.getUTCMonth();
        new_date.setUTCDate(day);
        test = function () {
          return new_month !== new_date.getUTCMonth();
        };
      }
      // Common date-resetting loop -- if date is beyond end of month, make it
      // end of month
      while (test()) {
        new_date.setUTCDate(--day);
        new_date.setUTCMonth(new_month);
      }
      return new_date;
    },
    moveYear: function (date, dir) {
      return this.moveMonth(date, dir * 12);
    },
    moveAvailableDate: function (date, dir, fn) {
      do {
        date = this[fn](date, dir);
        if (!this.dateWithinRange(date)) return false;
        fn = "moveDay";
      } while (this.dateIsDisabled(date));
      return date;
    },
    weekOfDateIsDisabled: function (date) {
      return $.inArray(date.getUTCDay(), (this || _global).o.daysOfWeekDisabled) !== -1;
    },
    dateIsDisabled: function (date) {
      return this.weekOfDateIsDisabled(date) || $.grep((this || _global).o.datesDisabled, function (d) {
        return isUTCEquals(date, d);
      }).length > 0;
    },
    dateWithinRange: function (date) {
      return date >= (this || _global).o.startDate && date <= (this || _global).o.endDate;
    },
    keydown: function (e) {
      if (!(this || _global).picker.is(":visible")) {
        if (e.keyCode === 40 || e.keyCode === 27) {
          // allow down to re-show picker
          this.show();
          e.stopPropagation();
        }
        return;
      }
      var dateChanged = false,
        dir,
        newViewDate,
        focusDate = (this || _global).focusDate || (this || _global).viewDate;
      switch (e.keyCode) {
        case 27:
          // escape
          if ((this || _global).focusDate) {
            (this || _global).focusDate = null;
            (this || _global).viewDate = (this || _global).dates.get(-1) || (this || _global).viewDate;
            this.fill();
          } else this.hide();
          e.preventDefault();
          e.stopPropagation();
          break;
        case 37: // left
        case 38: // up
        case 39: // right
        case 40:
          // down
          if (!(this || _global).o.keyboardNavigation || (this || _global).o.daysOfWeekDisabled.length === 7) break;
          dir = e.keyCode === 37 || e.keyCode === 38 ? -1 : 1;
          if ((this || _global).viewMode === 0) {
            if (e.ctrlKey) {
              newViewDate = this.moveAvailableDate(focusDate, dir, "moveYear");
              if (newViewDate) this._trigger("changeYear", (this || _global).viewDate);
            } else if (e.shiftKey) {
              newViewDate = this.moveAvailableDate(focusDate, dir, "moveMonth");
              if (newViewDate) this._trigger("changeMonth", (this || _global).viewDate);
            } else if (e.keyCode === 37 || e.keyCode === 39) {
              newViewDate = this.moveAvailableDate(focusDate, dir, "moveDay");
            } else if (!this.weekOfDateIsDisabled(focusDate)) {
              newViewDate = this.moveAvailableDate(focusDate, dir, "moveWeek");
            }
          } else if ((this || _global).viewMode === 1) {
            if (e.keyCode === 38 || e.keyCode === 40) {
              dir = dir * 4;
            }
            newViewDate = this.moveAvailableDate(focusDate, dir, "moveMonth");
          } else if ((this || _global).viewMode === 2) {
            if (e.keyCode === 38 || e.keyCode === 40) {
              dir = dir * 4;
            }
            newViewDate = this.moveAvailableDate(focusDate, dir, "moveYear");
          }
          if (newViewDate) {
            (this || _global).focusDate = (this || _global).viewDate = newViewDate;
            this.setValue();
            this.fill();
            e.preventDefault();
          }
          break;
        case 13:
          // enter
          if (!(this || _global).o.forceParse) break;
          focusDate = (this || _global).focusDate || (this || _global).dates.get(-1) || (this || _global).viewDate;
          if ((this || _global).o.keyboardNavigation) {
            this._toggle_multidate(focusDate);
            dateChanged = true;
          }
          (this || _global).focusDate = null;
          (this || _global).viewDate = (this || _global).dates.get(-1) || (this || _global).viewDate;
          this.setValue();
          this.fill();
          if ((this || _global).picker.is(":visible")) {
            e.preventDefault();
            e.stopPropagation();
            if ((this || _global).o.autoclose) this.hide();
          }
          break;
        case 9:
          // tab
          (this || _global).focusDate = null;
          (this || _global).viewDate = (this || _global).dates.get(-1) || (this || _global).viewDate;
          this.fill();
          this.hide();
          break;
      }
      if (dateChanged) {
        if ((this || _global).dates.length) this._trigger("changeDate");else this._trigger("clearDate");
        (this || _global).inputField.trigger("change");
      }
    },
    setViewMode: function (viewMode) {
      (this || _global).viewMode = viewMode;
      (this || _global).picker.children("div").hide().filter(".datepicker-" + DPGlobal.viewModes[(this || _global).viewMode].clsName).show();
      this.updateNavArrows();
      this._trigger("changeViewMode", new Date((this || _global).viewDate));
    }
  };
  var DateRangePicker = function (element, options) {
    $.data(element, "datepicker", this || _global);
    (this || _global).element = $(element);
    (this || _global).inputs = $.map(options.inputs, function (i) {
      return i.jquery ? i[0] : i;
    });
    delete options.inputs;
    (this || _global).keepEmptyValues = options.keepEmptyValues;
    delete options.keepEmptyValues;
    datepickerPlugin.call($((this || _global).inputs), options).on("changeDate", $.proxy((this || _global).dateUpdated, this || _global));
    (this || _global).pickers = $.map((this || _global).inputs, function (i) {
      return $.data(i, "datepicker");
    });
    this.updateDates();
  };
  DateRangePicker.prototype = {
    updateDates: function () {
      (this || _global).dates = $.map((this || _global).pickers, function (i) {
        return i.getUTCDate();
      });
      this.updateRanges();
    },
    updateRanges: function () {
      var range = $.map((this || _global).dates, function (d) {
        return d.valueOf();
      });
      $.each((this || _global).pickers, function (i, p) {
        p.setRange(range);
      });
    },
    clearDates: function () {
      $.each((this || _global).pickers, function (i, p) {
        p.clearDates();
      });
    },
    dateUpdated: function (e) {
      // `this.updating` is a workaround for preventing infinite recursion
      // between `changeDate` triggering and `setUTCDate` calling.  Until
      // there is a better mechanism.
      if ((this || _global).updating) return;
      (this || _global).updating = true;
      var dp = $.data(e.target, "datepicker");
      if (dp === undefined) {
        return;
      }
      var new_date = dp.getUTCDate(),
        keep_empty_values = (this || _global).keepEmptyValues,
        i = $.inArray(e.target, (this || _global).inputs),
        j = i - 1,
        k = i + 1,
        l = (this || _global).inputs.length;
      if (i === -1) return;
      $.each((this || _global).pickers, function (i, p) {
        if (!p.getUTCDate() && (p === dp || !keep_empty_values)) p.setUTCDate(new_date);
      });
      if (new_date < (this || _global).dates[j]) {
        // Date being moved earlier/left
        while (j >= 0 && new_date < (this || _global).dates[j] && ((this || _global).pickers[j].element.val() || "").length > 0) {
          (this || _global).pickers[j--].setUTCDate(new_date);
        }
      } else if (new_date > (this || _global).dates[k]) {
        // Date being moved later/right
        while (k < l && new_date > (this || _global).dates[k] && ((this || _global).pickers[k].element.val() || "").length > 0) {
          (this || _global).pickers[k++].setUTCDate(new_date);
        }
      }
      this.updateDates();
      delete (this || _global).updating;
    },
    destroy: function () {
      $.map((this || _global).pickers, function (p) {
        p.destroy();
      });
      $((this || _global).inputs).off("changeDate", (this || _global).dateUpdated);
      delete (this || _global).element.data().datepicker;
    },
    remove: alias("destroy", "Method `remove` is deprecated and will be removed in version 2.0. Use `destroy` instead")
  };
  function opts_from_el(el, prefix) {
    // Derive options from element data-attrs
    var data = $(el).data(),
      out = {},
      inkey,
      replace = new RegExp("^" + prefix.toLowerCase() + "([A-Z])");
    prefix = new RegExp("^" + prefix.toLowerCase());
    function re_lower(_, a) {
      return a.toLowerCase();
    }
    for (var key in data) if (prefix.test(key)) {
      inkey = key.replace(replace, re_lower);
      out[inkey] = data[key];
    }
    return out;
  }
  function opts_from_locale(lang) {
    // Derive options from locale plugins
    var out = {};
    // Check if "de-DE" style date is available, if not language should
    // fallback to 2 letter code eg "de"
    if (!dates[lang]) {
      lang = lang.split("-")[0];
      if (!dates[lang]) return;
    }
    var d = dates[lang];
    $.each(locale_opts, function (i, k) {
      if (k in d) out[k] = d[k];
    });
    return out;
  }
  var old = $.fn.datepicker;
  var datepickerPlugin = function (option) {
    var args = Array.apply(null, arguments);
    args.shift();
    var internal_return;
    this.each(function () {
      var $this = $(this || _global),
        data = $this.data("datepicker"),
        options = typeof option === "object" && option;
      if (!data) {
        var elopts = opts_from_el(this || _global, "date"),
          // Preliminary otions
          xopts = $.extend({}, defaults, elopts, options),
          locopts = opts_from_locale(xopts.language),
          // Options priority: js args, data-attrs, locales, defaults
          opts = $.extend({}, defaults, locopts, elopts, options);
        if ($this.hasClass("input-daterange") || opts.inputs) {
          $.extend(opts, {
            inputs: opts.inputs || $this.find("input").toArray()
          });
          data = new DateRangePicker(this || _global, opts);
        } else {
          data = new Datepicker(this || _global, opts);
        }
        $this.data("datepicker", data);
      }
      if (typeof option === "string" && typeof data[option] === "function") {
        internal_return = data[option].apply(data, args);
      }
    });
    if (internal_return === undefined || internal_return instanceof Datepicker || internal_return instanceof DateRangePicker) return this || _global;
    if ((this || _global).length > 1) throw new Error("Using only allowed for the collection of a single element (" + option + " function)");else return internal_return;
  };
  $.fn.datepicker = datepickerPlugin;
  var defaults = $.fn.datepicker.defaults = {
    assumeNearbyYear: false,
    autoclose: false,
    beforeShowDay: $.noop,
    beforeShowMonth: $.noop,
    beforeShowYear: $.noop,
    beforeShowDecade: $.noop,
    beforeShowCentury: $.noop,
    calendarWeeks: false,
    clearBtn: false,
    toggleActive: false,
    daysOfWeekDisabled: [],
    daysOfWeekHighlighted: [],
    datesDisabled: [],
    endDate: Infinity,
    forceParse: true,
    format: "mm/dd/yyyy",
    isInline: null,
    keepEmptyValues: false,
    keyboardNavigation: true,
    language: "en",
    minViewMode: 0,
    maxViewMode: 4,
    multidate: false,
    multidateSeparator: ",",
    orientation: "auto",
    rtl: false,
    startDate: -Infinity,
    startView: 0,
    todayBtn: false,
    todayHighlight: false,
    updateViewDate: true,
    weekStart: 0,
    disableTouchKeyboard: false,
    enableOnReadonly: true,
    showOnFocus: true,
    zIndexOffset: 10,
    container: "body",
    immediateUpdates: false,
    title: "",
    templates: {
      leftArrow: "&#x00AB;",
      rightArrow: "&#x00BB;"
    },
    showWeekDays: true
  };
  var locale_opts = $.fn.datepicker.locale_opts = ["format", "rtl", "weekStart"];
  $.fn.datepicker.Constructor = Datepicker;
  var dates = $.fn.datepicker.dates = {
    en: {
      days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
      daysShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      daysMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
      months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      monthsShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      today: "Today",
      clear: "Clear",
      titleFormat: "MM yyyy"
    }
  };
  var DPGlobal = {
    viewModes: [{
      names: ["days", "month"],
      clsName: "days",
      e: "changeMonth"
    }, {
      names: ["months", "year"],
      clsName: "months",
      e: "changeYear",
      navStep: 1
    }, {
      names: ["years", "decade"],
      clsName: "years",
      e: "changeDecade",
      navStep: 10
    }, {
      names: ["decades", "century"],
      clsName: "decades",
      e: "changeCentury",
      navStep: 100
    }, {
      names: ["centuries", "millennium"],
      clsName: "centuries",
      e: "changeMillennium",
      navStep: 1000
    }],
    validParts: /dd?|DD?|mm?|MM?|yy(?:yy)?/g,
    nonpunctuation: /[^ -\/:-@\u5e74\u6708\u65e5\[-`{-~\t\n\r]+/g,
    parseFormat: function (format) {
      if (typeof format.toValue === "function" && typeof format.toDisplay === "function") return format;
      // IE treats \0 as a string end in inputs (truncating the value),
      // so it's a bad format delimiter, anyway
      var separators = format.replace((this || _global).validParts, "\0").split("\0"),
        parts = format.match((this || _global).validParts);
      if (!separators || !separators.length || !parts || parts.length === 0) {
        throw new Error("Invalid date format.");
      }
      return {
        separators: separators,
        parts: parts
      };
    },
    parseDate: function (date, format, language, assumeNearby) {
      if (!date) return undefined;
      if (date instanceof Date) return date;
      if (typeof format === "string") format = DPGlobal.parseFormat(format);
      if (format.toValue) return format.toValue(date, format, language);
      var fn_map = {
          d: "moveDay",
          m: "moveMonth",
          w: "moveWeek",
          y: "moveYear"
        },
        dateAliases = {
          yesterday: "-1d",
          today: "+0d",
          tomorrow: "+1d"
        },
        parts,
        part,
        dir,
        i,
        fn;
      if (date in dateAliases) {
        date = dateAliases[date];
      }
      if (/^[\-+]\d+[dmwy]([\s,]+[\-+]\d+[dmwy])*$/i.test(date)) {
        parts = date.match(/([\-+]\d+)([dmwy])/gi);
        date = new Date();
        for (i = 0; i < parts.length; i++) {
          part = parts[i].match(/([\-+]\d+)([dmwy])/i);
          dir = Number(part[1]);
          fn = fn_map[part[2].toLowerCase()];
          date = Datepicker.prototype[fn](date, dir);
        }
        return Datepicker.prototype._zero_utc_time(date);
      }
      parts = date && date.match((this || _global).nonpunctuation) || [];
      function applyNearbyYear(year, threshold) {
        if (threshold === true) threshold = 10;

        // if year is 2 digits or less, than the user most likely is trying to get a recent century
        if (year < 100) {
          year += 2000;
          // if the new year is more than threshold years in advance, use last century
          if (year > new Date().getFullYear() + threshold) {
            year -= 100;
          }
        }
        return year;
      }
      var parsed = {},
        setters_order = ["yyyy", "yy", "M", "MM", "m", "mm", "d", "dd"],
        setters_map = {
          yyyy: function (d, v) {
            return d.setUTCFullYear(assumeNearby ? applyNearbyYear(v, assumeNearby) : v);
          },
          m: function (d, v) {
            if (isNaN(d)) return d;
            v -= 1;
            while (v < 0) v += 12;
            v %= 12;
            d.setUTCMonth(v);
            while (d.getUTCMonth() !== v) d.setUTCDate(d.getUTCDate() - 1);
            return d;
          },
          d: function (d, v) {
            return d.setUTCDate(v);
          }
        },
        val,
        filtered;
      setters_map["yy"] = setters_map["yyyy"];
      setters_map["M"] = setters_map["MM"] = setters_map["mm"] = setters_map["m"];
      setters_map["dd"] = setters_map["d"];
      date = UTCToday();
      var fparts = format.parts.slice();
      // Remove noop parts
      if (parts.length !== fparts.length) {
        fparts = $(fparts).filter(function (i, p) {
          return $.inArray(p, setters_order) !== -1;
        }).toArray();
      }
      // Process remainder
      function match_part() {
        var m = this.slice(0, parts[i].length),
          p = parts[i].slice(0, m.length);
        return m.toLowerCase() === p.toLowerCase();
      }
      if (parts.length === fparts.length) {
        var cnt;
        for (i = 0, cnt = fparts.length; i < cnt; i++) {
          val = parseInt(parts[i], 10);
          part = fparts[i];
          if (isNaN(val)) {
            switch (part) {
              case "MM":
                filtered = $(dates[language].months).filter(match_part);
                val = $.inArray(filtered[0], dates[language].months) + 1;
                break;
              case "M":
                filtered = $(dates[language].monthsShort).filter(match_part);
                val = $.inArray(filtered[0], dates[language].monthsShort) + 1;
                break;
            }
          }
          parsed[part] = val;
        }
        var _date, s;
        for (i = 0; i < setters_order.length; i++) {
          s = setters_order[i];
          if (s in parsed && !isNaN(parsed[s])) {
            _date = new Date(date);
            setters_map[s](_date, parsed[s]);
            if (!isNaN(_date)) date = _date;
          }
        }
      }
      return date;
    },
    formatDate: function (date, format, language) {
      if (!date) return "";
      if (typeof format === "string") format = DPGlobal.parseFormat(format);
      if (format.toDisplay) return format.toDisplay(date, format, language);
      var val = {
        d: date.getUTCDate(),
        D: dates[language].daysShort[date.getUTCDay()],
        DD: dates[language].days[date.getUTCDay()],
        m: date.getUTCMonth() + 1,
        M: dates[language].monthsShort[date.getUTCMonth()],
        MM: dates[language].months[date.getUTCMonth()],
        yy: date.getUTCFullYear().toString().substring(2),
        yyyy: date.getUTCFullYear()
      };
      val.dd = (val.d < 10 ? "0" : "") + val.d;
      val.mm = (val.m < 10 ? "0" : "") + val.m;
      date = [];
      var seps = $.extend([], format.separators);
      for (var i = 0, cnt = format.parts.length; i <= cnt; i++) {
        if (seps.length) date.push(seps.shift());
        date.push(val[format.parts[i]]);
      }
      return date.join("");
    },
    headTemplate: "<thead>" + "<tr>" + "<th colspan=\"7\" class=\"datepicker-title\"></th>" + "</tr>" + "<tr>" + "<th class=\"prev\">" + defaults.templates.leftArrow + "</th>" + "<th colspan=\"5\" class=\"datepicker-switch\"></th>" + "<th class=\"next\">" + defaults.templates.rightArrow + "</th>" + "</tr>" + "</thead>",
    contTemplate: "<tbody><tr><td colspan=\"7\"></td></tr></tbody>",
    footTemplate: "<tfoot>" + "<tr>" + "<th colspan=\"7\" class=\"today\"></th>" + "</tr>" + "<tr>" + "<th colspan=\"7\" class=\"clear\"></th>" + "</tr>" + "</tfoot>"
  };
  DPGlobal.template = "<div class=\"datepicker\">" + "<div class=\"datepicker-days\">" + "<table class=\"table-condensed\">" + DPGlobal.headTemplate + "<tbody></tbody>" + DPGlobal.footTemplate + "</table>" + "</div>" + "<div class=\"datepicker-months\">" + "<table class=\"table-condensed\">" + DPGlobal.headTemplate + DPGlobal.contTemplate + DPGlobal.footTemplate + "</table>" + "</div>" + "<div class=\"datepicker-years\">" + "<table class=\"table-condensed\">" + DPGlobal.headTemplate + DPGlobal.contTemplate + DPGlobal.footTemplate + "</table>" + "</div>" + "<div class=\"datepicker-decades\">" + "<table class=\"table-condensed\">" + DPGlobal.headTemplate + DPGlobal.contTemplate + DPGlobal.footTemplate + "</table>" + "</div>" + "<div class=\"datepicker-centuries\">" + "<table class=\"table-condensed\">" + DPGlobal.headTemplate + DPGlobal.contTemplate + DPGlobal.footTemplate + "</table>" + "</div>" + "</div>";
  $.fn.datepicker.DPGlobal = DPGlobal;

  /* DATEPICKER NO CONFLICT
  * =================== */

  $.fn.datepicker.noConflict = function () {
    $.fn.datepicker = old;
    return this || _global;
  };

  /* DATEPICKER VERSION
   * =================== */
  $.fn.datepicker.version = "1.10.0";
  $.fn.datepicker.deprecated = function (msg) {
    var console = window.console;
    if (console && console.warn) {
      console.warn("DEPRECATED: " + msg);
    }
  };

  /* DATEPICKER DATA-API
  * ================== */

  $(document).on("focus.datepicker.data-api click.datepicker.data-api", "[data-provide=\"datepicker\"]", function (e) {
    var $this = $(this || _global);
    if ($this.data("datepicker")) return;
    e.preventDefault();
    // component click requires us to explicitly show it
    datepickerPlugin.call($this, "show");
  });
  $(function () {
    datepickerPlugin.call($("[data-provide=\"datepicker-inline\"]"));
  });
});
export default {};